export default {
  development: {
    apiKey: "AIzaSyAQxPTNVhjudwyvI3ABNEKxv08PMNP407Y",
    authDomain: "namma-test.firebaseapp.com",
    databaseURL: "https://namma-test.firebaseio.com",
    projectId: "namma-test",
    storageBucket: "namma-test.appspot.com",
    messagingSenderId: "95833919391",
    appId: "1:95833919391:web:9929a054238dc8cf6e37aa",
  },
  staging: {
    apiKey: "AIzaSyAQxPTNVhjudwyvI3ABNEKxv08PMNP407Y",
    authDomain: "namma-test.firebaseapp.com",
    databaseURL: "https://namma-test.firebaseio.com",
    projectId: "namma-test",
    storageBucket: "namma-test.appspot.com",
    messagingSenderId: "95833919391",
    appId: "1:95833919391:web:9929a054238dc8cf6e37aa",
  },
  production: {
    apiKey: "AIzaSyCro5qf9T2BX_dMFXrVzeY4CX9HlXMxqA4",
    authDomain: "namma-251111.firebaseapp.com",
    databaseURL: "https://namma-251111.firebaseio.com",
    projectId: "namma-251111",
    storageBucket: "namma-251111.appspot.com",
    messagingSenderId: "375074232143",
    appId: "1:375074232143:web:5c99ff9d2550bbd839d73a",
  },
};
