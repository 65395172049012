import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetCurrentLoanRequest as resetCurrentLoanRequestAction } from "../../state/loans/actions";
import { signOutUser as signOutUserAction } from "../../state/user/actions";
import { selectUserAuth } from "../../state/user/selectors";
import { NDivider } from "../materialStyledComponents";
import NavbarContainer from "./../NavbarContainer";
import "./styles.scss";

function Navbar(props) {
  let navigate = useNavigate();
  const { signOutUser, user, resetCurrentLoanRequest, userAuth, userVerification } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>

      <div className="navbar-brand">
        <div className="navbar-item">
          <Link to="/">
            <img className="image" src={props.logo} alt="Logo" />
          </Link>
        </div>
        <div
          className={"navbar-burger burger" + (menuOpen ? " is-active" : "")}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
        <div className="navbar-end">
          <Link
            className="navbar-item is-hoverable dropdown"
            to="/product"
            onClick={(event) => { event.target.blur(); }}
          >
            What do we provide?
            <div className="navbar-dropdown is-boxed">
              <Link
                className="navbar-item"
                to="/product-lend"
                onClick={(event) => { event.target.blur(); }}
              >
                Loan Management Services
              </Link>
              <NDivider></NDivider>
              <Link
                className="navbar-item"
                to="/product-gift"
                onClick={(event) => { event.target.blur(); }}
              >
                Mortgage Gift Letter Creation
              </Link>

            </div>
          </Link>
          <Link
            className="navbar-item is-hoverable dropdown"
            to="/know"
            onClick={(event) => { event.target.blur(); }}
          >
            Resources
            <div className="navbar-dropdown is-boxed">
              <Link
                className="navbar-item"
                to="/product/loan-agreement-template-family-friends"
                onClick={(event) => { event.target.blur(); }}
              >
                Our Loan Agreement
              </Link>
              <NDivider></NDivider>
              <Link
                className="navbar-item"
                to="/know/taxes-implications-lending-borrowing-family-friends"
                onClick={(event) => { event.target.blur(); }}
              >
                Paying Taxes on Interest
              </Link>
              <Link
                className="navbar-item"
                to="/know/charge-interest-family-friend-loan"
                onClick={(event) => { event.target.blur(); }}
              >
                Charging Interest
              </Link>
              <Link
                className="navbar-item"
                to="/know/loan-amortization-calculator-interest-principal-schedule"
                onClick={(event) => { event.target.blur(); }}
              >
                How we amortize?
              </Link>
              <Link
                className="navbar-item"
                to="/know/avoid-common-lending-pitfalls-relationship-issues-regrets"
                onClick={(event) => { event.target.blur(); }}
              >
                Tips to keep the process smooth
              </Link>
            </div>
          </Link>
          <Link to="/faq" className="navbar-item">
            FAQs
          </Link>
          {user.id && userAuth && userVerification && (
            <div className="navbar-item has-dropdown is-hoverable">
              <Link
                className="navbar-link"
                to="/"
                onClick={(event) => { event.target.blur(); }}
              >
                {`${user.firstName} ${user.lastName}`}
              </Link>
              <div className="navbar-dropdown is-boxed">
                <Link
                  className="navbar-item"
                  to="/loan-terms?requestType=borrow"
                  onClick={(event) => { resetCurrentLoanRequest(); event.target.blur(); }}
                >
                  Borrow Money
                </Link>
                <Link
                  className="navbar-item"
                  to="/loan-terms?requestType=lend"
                  onClick={(event) => { resetCurrentLoanRequest(); event.target.blur(); }}
                >
                  Lend Money
                </Link>
                <Link
                  className="navbar-item"
                  to="/gift-terms"
                  onClick={(event) => { resetCurrentLoanRequest(); event.target.blur(); }}
                >
                  Request A Gift
                </Link>
                <Link
                  className="navbar-item"
                  to="/dashboard"
                  onClick={(event) => { event.target.blur(); }}
                >
                  My Dashboard
                </Link>
                <Link
                  className="navbar-item"
                  to="/profile/1"
                  onClick={(event) => { event.target.blur(); }}
                >
                  My Profile
                </Link>
                <Link
                  className="navbar-item"
                  to="/"
                  onClick={e => {
                    e.preventDefault();
                    signOutUser();
                    navigate(`/`);
                  }}
                >
                  Sign out
                </Link>
              </div>
            </div>
          )}

          {(!user.id || !userAuth || !userVerification) && (
            <>
              <Link to="/signup" className="navbar-item">
                Sign Up
              </Link>
              <Link
                className="navbar-item nav-signin-btn"
                to="/signin"
                onClick={(event) => { event.target.blur(); }}
              >
                Sign in
              </Link>
            </>
          )}
        </div>
      </div>

    </NavbarContainer >
  );
}
const mapStateToProps = function (state) {
  return {
    user: state.user,
    userAuth: selectUserAuth(state),
    userVerification: state.user.hasVerifiedEmail
  };
};

const mapDispatchToProps = {
  signOutUser: signOutUserAction,
  resetCurrentLoanRequest: resetCurrentLoanRequestAction
};


export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
