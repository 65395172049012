import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SectionButton from "../SectionButton";
import "./styles.scss";



function Footer(props) {
	return (
		<div className="container-basic footer-container">
			<div className="container HomepageFeatures">
				<div className="footer-header hero-head">
					Access capital within your network with dignity and promise.
				</div>
				<div className="footer-header">
					<Link to="/get-started">
						<SectionButton backgroundColor={"orange"}>
							Get started
						</SectionButton>
					</Link>
				</div>
			</div>

			<div className="FooterComponent__container container">
				<div className="columns is-variable">
					<div className="column is-1">
						<Link className="brand" to="/">
							<div className="brand-icon">
								<img
									className="FooterComponent__logo"
									src={props.logo}
									alt="Logo"
								/>
							</div>
						</Link>
					</div>
					<div className="column is-7 ">
						{props.description && (
							<p className="FooterComponent__description">
								{props.description}
							</p>
						)}
					</div>

					<div className="column is-2">
						<p className="menu-label">Company</p>
						<ul className="menu-list">
							<li>
								<Link to="/product-lend">Our Services</Link>
							</li>
							<li>
								<Link to="/faq">FAQs</Link>
							</li>
							<li>
								<Link to="/policies">Policies</Link>
							</li>
							<li>
								<Link to="/about/">About</Link>
							</li>
						</ul>
					</div>
					<div className="column is-2">
						<p className="menu-label">Community</p>
						<ul className="menu-list">
							<li>
								<Link to="/know">Learn</Link>
							</li>
							<li>
								<a
									href="mailto:support@namma.co"
									target="_blank"
								>
									Contact Support
								</a>
							</li>
							<a
								href="https://www.facebook.com/NammaDotCo"
								target="_blank"
							>
								Follow on{" "}
								<img
									src="../_Icons/facebook.svg"
									className="logo-img"
									alt="Namma Facebook page"
								></img>
							</a>
							<a
								href="https://www.instagram.com/namma_co"
								target="_blank"
							>
								Follow on{" "}
								<img
									src="../_Icons/instagram.svg"
									className="logo-img"
									alt="Namma Facebook page"
								></img>
							</a>
						</ul>
					</div>

				</div>
				{props.copyright && (
					<p className="FooterComponent__copywrite">{props.copyright}</p>
				)}
			</div>
		</div>
	);
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
